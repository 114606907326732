const Appdefs = window.cccisd.appDefs;

export const getHomeRoute = () => {
    let route = '/';
    try {
        const homepageHandle = Appdefs.navs.find(nav => nav.homepage).homepage;
        route = Appdefs.routes.find(r => r.handle === homepageHandle).url;
    } catch (e) {
        route = '/';
    }

    return route;
};
